import React from 'react'
import { UWPAddress, UWPEnrollment } from 'types/uwp'
import * as styles from './confirmation-results.module.css'

type GridItemProps = {
  label: string
  userInfo: string | string[] | null
}

type ConfirmationResultsProps = {
  enrollmentObj: UWPEnrollment
}

const addressLines = (address: UWPAddress) => {
  const { streetAddress, extendedAddress, locality, region, postalCode } =
    address

  return [streetAddress, extendedAddress, `${locality}, ${region}`, postalCode]
}

const GridItem = ({ label, userInfo }: GridItemProps) => {
  return (
    <>
      <div className={styles.gridItem}>
        <strong>{label}</strong>
      </div>
      <div className={styles.gridItem}>
        {!Array.isArray(userInfo) && userInfo}
        {Array.isArray(userInfo) &&
          userInfo.map((value, index) => <div key={index}>{value}</div>)}
      </div>
    </>
  )
}

const ConfirmationResults = ({ enrollmentObj }: ConfirmationResultsProps) => {
  return (
    <div className={styles.confirmationResults}>
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <h2>Here’s what you submitted</h2>
        </div>
        <div className={styles.tableResult}>
          <div className={styles.grid}>
            <GridItem
              label="Email Address"
              userInfo={enrollmentObj.emailAddress}
            />
            <GridItem label="First Name" userInfo={enrollmentObj.firstName} />
            <GridItem label="Last Name" userInfo={enrollmentObj.lastName} />
            <GridItem
              label="Street Address"
              userInfo={
                enrollmentObj.address
                  ? addressLines(enrollmentObj.address)
                  : null
              }
            />
          </div>
        </div>
        {!enrollmentObj.addressSameAsMailing && (
          <div className={styles.tableResult}>
            <div className={styles.grid}>
              {!enrollmentObj.addressSameAsMailing && (
                <GridItem
                  label="Mailing Address"
                  userInfo={
                    enrollmentObj.mailingAddress
                      ? addressLines(enrollmentObj.mailingAddress)
                      : null
                  }
                />
              )}
            </div>
          </div>
        )}
        <div className={styles.tableResult}>
          <div className={styles.grid}>
            <GridItem
              label="Account Number"
              userInfo={enrollmentObj.utilityAccountNumber}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationResults
